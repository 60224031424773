import { preventDefaultAndStopPropagation as createEventHandler } from 'utils/ui/createEventHandler';
import { noOp } from 'utils/core/funcy';

export default ({ updateItemQuantityForPreOrder = noOp, id } = {}) => {
  const onChangeCartQuantity = (newQuantity) => {
    if (newQuantity === undefined) return;
    updateItemQuantityForPreOrder({ productId: id, newQuantity });
  };

  const onDelete = createEventHandler(() => {
    updateItemQuantityForPreOrder({ productId: id, newQuantity: 0 });
  });

  return {
    onChangeCartQuantity,
    onDelete,
    productId: id,
  };
};
