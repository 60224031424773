import {
  createImage,
  isSourced,
  isValidImage,
  missingCategory,
  missingParentCategory,
} from '../utils';

import { numberOrZero } from 'utils/ui/mappers';

const createPricingVariantMapper = productId => ({
  price = 0,
  uom = null,
  weight = 0,
} = {}) => ({
  productId,
  price: price / 100,
  uom,
  weight: weight || 0,
  hasUOM: !!uom && weight > 0,
});

export const mapInitialDetails = ({
  id,
  sourcedProductId,
  imageUrl,
  available,
  minCBDMax,
  maxCBDMax,
  minTHCMax,
  maxTHCMax,
  minTotalOptional,
  maxTotalOptional,
  minTotalTerpenes,
  maxTotalTerpenes,
  price,
  size,
  discount,
  bulkGramsAvailable,
  quantityType,
  name,
  productCategoryParentId,
  productCategoryParentName,
  productCategoryId,
  productCategoryName,
  strainName = '',
  variants,
  ...rest
}) => {
  const listingName = `${strainName || ''} ${name}`.trim();
  return {
    id,
    ...rest,
    quantityType,
    name,
    listingName,
    strainName,
    sourcedProductId: Number(sourcedProductId),
    bulkGramsAvailable: numberOrZero(bulkGramsAvailable),
    available: Number(available),
    minTHCMax: numberOrZero(minTHCMax),
    maxTHCMax: numberOrZero(maxTHCMax),
    minCBDMax: numberOrZero(minCBDMax),
    maxCBDMax: numberOrZero(maxCBDMax),
    minTotalTerpenes: numberOrZero(minTotalTerpenes),
    maxTotalTerpenes: numberOrZero(maxTotalTerpenes),
    minTotalOptional: numberOrZero(minTotalOptional),
    maxTotalOptional: numberOrZero(maxTotalOptional),
    discount: numberOrZero(discount),
    price: Number(price),
    productCategoryParentId: productCategoryParentId || missingParentCategory.id,
    productCategoryParentName: productCategoryParentName || missingParentCategory.name,
    productCategoryId: productCategoryId || missingCategory.id,
    productCategoryName: productCategoryName || missingCategory.name,
    size: numberOrZero(size),
    imageUrl,
    isSourced: isSourced({ bulkGramsAvailable, sourcedProductId }),
    isCountBased: quantityType === 'CountBased',
    variants: variants.map(createPricingVariantMapper(id)),
    images: [createImage(imageUrl, imageUrl)].filter(isValidImage).map((data, index) => ({
      ...data,
      id: index,
    })),
  };
};

export const __test__ = { createPricingVariantMapper };
