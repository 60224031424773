import { toCurrency, toUomAbbreviation } from 'utils/ui/mappers';
import { DeleteFilled } from '@ant-design/icons';
import Image from 'components/Image';
import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import config from 'config';
import { noOp } from 'src/utils/core/funcy';
import { productCard } from './styles';

const { Option } = Select;

const ProductCard = ({
  imageUrl,
  strainName,
  name,
  price,
  uom,
  available,
  unitWeight,
  unitWeightUOM,
  quantityInCart,
  onChangeCartQuantity = noOp,
  onDelete = noOp,
} = {}) => {
  const isEach = uom !== 'Grams';
  const unitPrice = !isEach ? price / unitWeight : price;
  const quantityOptions = isEach ? _.range(1, Math.min(51, available)) : [1, 3.5, 7, 14, 28];
  const displayName = strainName ? `${strainName} ${name}` : name;
  const weightInCart = isEach
    ? `${quantityInCart}`
    : `${unitWeight * quantityInCart + toUomAbbreviation(unitWeightUOM)}`;

  const handleChangeCartQuantity = newQuantity => {
    onChangeCartQuantity(newQuantity);
  };

  return (
    <div className={productCard}>
      <div className="image-name-container">
        <Image
          src={imageUrl || config.images.fallback}
          alt={`image of ${displayName}`}
          fallback={config.images.fallback}
        />
      </div>
      <div className="strain-name-container">
        <h4>
          {displayName} {!isEach && weightInCart}
        </h4>
      </div>
      <div className="quantity-select">
        <Select
          style={{ width: '100%' }}
          placeholder={!isEach ? weightInCart : quantityInCart}
          onChange={handleChangeCartQuantity}
        >
          {quantityOptions.map(num => (
            <Option value={num}>
              {num}{!isEach && 'g'}
            </Option>
          ))}
        </Select>
      </div>
      <div className="single-item-price price-label">@ {toCurrency(unitPrice)}</div>
      <div className="total-cost price-label">{toCurrency(quantityInCart * price)}</div>
      <div className="delete-item">
        <DeleteFilled onClick={onDelete} />
      </div>
    </div>
  );
};

export default ProductCard;
