import { createEventHandler } from 'src/utils/ui/createEventHandler';
import { noOp } from 'src/utils/core/funcy';

const createVariantId = (productId, index) => `${productId}-${index}`;

const uiVariantMapper = ({
  uom,
  weight,
  productId,
  index,
  productModalVisible,
  lastVariantAdded,
  name,
  ...rest
}) => ({
  ...rest,
  weight,
  uom,
  unitWeightUOM: uom,
  quantity: weight === 0 || uom !== 'g' ? 1 : weight,
  variantId: createVariantId(productId, index),
  modalAttached:
    lastVariantAdded === createVariantId(productId, index) && productModalVisible,
  productId,
  name,
});

export default ({
  variants = [],
  productModalVisible = false,
  lastVariantAdded = '',
  addItemToPreOrder = noOp,
  brand,
  name,
}) => {
  variants = variants.map((variant, index) =>
    uiVariantMapper({
      ...variant,
      productModalVisible,
      lastVariantAdded,
      index,
    })
  );

  const useEachTemplate = variants.length === 1 || variants[0].uom !== 'g';
  const lowestPrice = variants[0] ? variants[0].price.toFixed(2) : undefined;
  const highestPrice = variants[0]
    ? variants[variants.length - 1].price.toFixed(2)
    : undefined;
  const overlayPlacement = window.matchMedia('(max-width: 768px)').matches
    ? 'top'
    : 'left';

  const onAddItemToCart = createEventHandler(({ productId, quantity, variantId }) => {
    addItemToPreOrder({ productId, quantity, variantId });
  });

  return {
    variants,
    addItemToPreOrder,
    onAddItemToCart,
    productModalVisible,
    lowestPrice,
    highestPrice,
    useEachTemplate,
    name,
    brand,
    overlayPlacement,
  };
};

export const __test__ = {
  uiVariantMapper,
};
