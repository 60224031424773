import { Button, Popover, Select, Space } from 'antd';
import React, { useState } from 'react';
import { buttonGroupStyles, cartOverlayStyles, popoverTitleStyles } from './styles';
import { CheckCircleOutlined } from '@ant-design/icons';
import { noOp } from 'src/utils/core/funcy';

const { Option } = Select;

const AddToCartGroup = ({
  variants = [],
  supportsPreOrders = false,
  onAddItemToCart = noOp,
  lowestPrice = 0,
  highestPrice = 0,
  useEachTemplate = false,
  productModalVisible = false,
  lastVariantAdded = '',
  name = '',
  brand = '',
  viewCart = noOp,
  startCheckout = noOp,
  overlayPlacement = '',
} = {}) => {
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [displayError, setDisplayError] = useState(false);

  const errorMessage = displayError ? (
    <span className="error-message">Select the weight you want to add</span>
  ) : null;

  const handleSelectWeight = variant => {
    const { quantity, variantId, productId, price } = JSON.parse(variant);
    setDisplayError(false);
    setSelectedItem({ productId, quantity, variantId, price });
  };

  const handleAddItemToCart = () => {
    if (selectedItem === undefined) {
      setDisplayError(true);
    }
    if (selectedItem !== undefined) {
      // eslint-disable-next-line no-unused-vars
      let { price, ...selectedItemForCart } = selectedItem;
      onAddItemToCart(selectedItemForCart);
      setSelectedItem(undefined);
    }
  };

  const selectSizeTemplate = variants => (
    <>
      {selectedItem === undefined && (
        <span className="price-label">
          ${lowestPrice} - ${highestPrice}
        </span>
      )}
      {selectedItem !== undefined && (
        <span className="price-label">${selectedItem.price.toFixed(2)}</span>
      )}
      <Select
        placeholder="Select a Weight"
        className="size-select"
        onChange={handleSelectWeight}
      >
        {variants.map(variant => {
          const { weight, unitWeightUOM, price } = variant;
          const optionLabel = `${weight}${unitWeightUOM} - $${price.toFixed(2)}`;
          return (
            <Option title={optionLabel} value={JSON.stringify(variant)}>
              {weight}
              {unitWeightUOM} - ${price.toFixed(2)}
            </Option>
          );
        })}
      </Select>
      {supportsPreOrders && <div className='add-button'>
        {errorMessage}
        <Button onClick={handleAddItemToCart}>Add to Cart</Button>
      </div>}
    </>
  );

  const soldAsEachTemplate = ({ productId, quantity, variantId, price }) => (
    <div className="sold-as-each">
      <span className="price-label">${price.toFixed(2)}</span>
      {supportsPreOrders && (
        <Button onClick={() => onAddItemToCart({ productId, quantity, variantId })}>
          Add to Cart
        </Button>
      )}
    </div>
  );
 ;

  const template = useEachTemplate
    ? soldAsEachTemplate(variants[0])
    : selectSizeTemplate(variants);
  const includesLastVariantAdded = variants.filter(
    ({ variantId }) => variantId === lastVariantAdded
  ).length;
  const lastAdded = variants.filter(({ variantId }) => variantId === lastVariantAdded);

  const itemDetails = () => {
    if (lastAdded.length === 0) return '';
    // eslint-disable-next-line prefer-destructuring
    const { price, unitWeightUOM, weight, hasUOM, quantity } = lastAdded[0];
    const title = brand ? `${brand} ${name}` : name;
    const qtyAtPrice = `Qty: ${
      hasUOM ? `1 x ${weight}${unitWeightUOM}` : quantity
    } @ $${price.toFixed(2)}`;
    const productTitle = hasUOM ? `${title} ${weight}${unitWeightUOM}` : title;
    const totalCost = hasUOM ? price : quantity * price;

    return (
      <div className={cartOverlayStyles}>
        <h4>{productTitle}</h4>
        <div className="justify-between">
          {qtyAtPrice}
          <span>
            ${totalCost.toFixed(2)}
          </span>
        </div>
        <p>Other discounts may be taken at the register</p>
        <div className="justify-between popover-buttons">
          <Button type="ghost" onClick={viewCart}>View Cart</Button>
          <Button type="primary" onClick={startCheckout}>Checkout</Button>
        </div>
      </div>
    );
  };

  const popoverTitle = (
    <Space className={popoverTitleStyles}>
      <CheckCircleOutlined />
      Added to cart!
    </Space>
  );

  return (
    <Popover
      title={popoverTitle}
      content={itemDetails}
      visible={productModalVisible && includesLastVariantAdded}
      icon={<CheckCircleOutlined />}
      placement={overlayPlacement}
    >
      <div className={buttonGroupStyles(displayError)}>{template}</div>
    </Popover>
  );
};

export default AddToCartGroup;
